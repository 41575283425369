import styled from "@emotion/styled";
import Img from "gatsby-image";
import { Box } from "rebass";

export const MDWrapper = styled(Box)`
  margin: 0 auto;
  max-width: 66rem;
  width: 100%;

  img {
    height: auto;
    width: 100%;
  }

  p {
    color: ${({ theme }) => theme.colors.text};
    font-size: ${({ theme }) => theme.fontSizes.sm};
    line-height: 1.5;
    margin-bottom: 3.4rem;

    ${({ theme }) => theme.mq.md`
        font-size: ${theme.fontSizes.md};
        margin-bottom: 3.8rem;
    `}
  }

  h1 {
    color: ${({ theme }) => theme.colors.text};
    font-size: ${({ theme }) => theme.fontSizes["lg"]};
    font-weight: ${({ theme }) => theme.fontWeights.semibold};
    line-height: 1.4;
    margin-bottom: 1.6rem;
    margin-top: 6rem;

    ${({ theme }) => theme.mq.md`
        font-size: ${theme.fontSizes["3.5xl"]};
    `}
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${({ theme }) => theme.colors.text};
    font-size: ${({ theme }) => theme.fontSizes["md"]};
    font-weight: ${({ theme }) => theme.fontWeights.semibold};
    line-height: 1.4;
    margin-bottom: 1.6rem;
    margin-top: 4rem;

    ${({ theme }) => theme.mq.md`
        font-size: ${theme.fontSizes["2xl"]};
    `}
  }

  ol {
    counter-reset: custom-counter;
    list-style: none;

    li {
      align-items: baseline;
      color: ${({ theme }) => theme.colors.text};
      counter-increment: custom-counter;
      flex-direction: row;
      font-size: ${({ theme }) => theme.fontSizes.sm};
      line-height: 1.5;
      padding-left: 20px;
      position: relative;

      ${({ theme }) => theme.mq.md`
        font-size: ${theme.fontSizes.md};
      `}

      &::before {
        content: counter(custom-counter) ".";
        left: 0;
        position: absolute;
        top: 0;
      }
    }
  }

  ul {
    list-style: none;

    li {
      font-size: ${({ theme }) => theme.fontSizes.sm};
      align-items: baseline;
      color: ${({ theme }) => theme.colors.text};
      flex-direction: row;
      line-height: 1.5;
      padding-left: 20px;
      position: relative;

      &::before {
        background: ${({ theme }) => theme.colors.text};
        border-radius: 50%;
        content: "";
        flex-shrink: 0;
        font-size: ${({ theme }) => theme.fontSizes.sm};
        height: 6px;
        left: 4px;
        position: absolute;
        top: 7px;
        width: 6px;
      }

      ${({ theme }) => theme.mq.md`
        font-size: ${theme.fontSizes.md};

        &:before{
          top: 9px;
        }
      `}
    }
  }

  ul,
  ol {
    &:not(:last-child) {
      margin-bottom: 3.4rem;

      ${({ theme }) => theme.mq.md`
      margin-bottom: 3.8rem;
        `}
    }

    li {
      ul {
        margin-top: 5px;

        ${({ theme }) => theme.mq.md`
        margin-top: 10px;
        `}

        + p {
          margin-top: -10px;

          ${({ theme }) => theme.mq.md`
          margin-top: -15px;
        `}
        }
      }

      p {
        margin: 0;
      }

      &:not(:last-child) {
        margin-bottom: 10px;

        ${({ theme }) => theme.mq.md`
        margin-bottom: 15px;
        `}
      }
    }
  }
`;

export const Image = styled(Img)`
  height: 100%;
  width: 100%;
  border-radius: 10px;
`;
