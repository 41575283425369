/* eslint-disable react/prop-types */
import React from "react";
import { graphql, Link, navigate } from "gatsby";
import format from "date-fns/format";
import { useMeasure } from "react-use";
import { Box, Flex, Heading, Text } from "rebass";
import Markdown from "react-markdown";
import Layout from "@layouts";
import LeftArrow from "@icons/left-arrow.svg";
import { Container, SocialShare } from "@components";
import Logo from "@icons/opay-logo.svg";
import { MDWrapper, Image } from "./style";

export const query = graphql`
  query Post($slug: String!) {
    posts: allStrapiPost(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          id
          strapiId
          title
          excerpt
          published_at
          slug
          content
          image {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 100) {
                ...GatsbyImageSharpFluid
                ...GatsbyImageSharpFluidLimitPresentationSize
              }
            }
          }
          category {
            name
            slug
          }
          author {
            name
          }
        }
      }
    }
  }
`;

const PostTemplate = ({ data }) => {
  const [ref, { width }] = useMeasure();
  const post = data.posts.edges[0].node;
  const { author, category, content, excerpt, image, published_at, title } = post;

  const aspectHeight = width * 0.5;
  const poster = image?.childImageSharp?.fluid;

  return (
    <Layout showNav={false} postTitle={title} scrollOffset={180} seoTitle={title}>
      <Box pt="11rem">
        <Container>
          <Flex mb={["3rem", null, "5rem"]} flexDirection={["column", null, "row"]}>
            <Box
              onClick={() => navigate("/")}
              flexShrink="0"
              mr={[0, null, "3rem"]}
              mb={["1.7rem", null, 0]}
              sx={{
                cursor: "pointer",
              }}
            >
              <LeftArrow />
            </Box>

            <Box flex={1}>
              <Flex alignItems="center" flexWrap="wrap" mb="3rem">
                <Text variant="body">By {author?.name}</Text>
                <Box width="1px" height="2rem" bg="text" mx="1.5rem" />

                <Text
                  color="primary"
                  as={Link}
                  to={`/category/${category?.slug}`}
                  variant="body"
                  sx={{
                    textDecoration: "none",
                    "&:visited": {
                      color: "primary",
                    },
                  }}
                >
                  {category?.name}
                </Text>

                <Box width="6px" height="6px" sx={{ borderRadius: "50%" }} bg="header" mx="1rem" />

                <Text variant="body" opacity="0.4">
                  {format(new Date(published_at), "MMM dd, yyyy")}
                </Text>
              </Flex>

              <Heading mb="1rem">{title}</Heading>
              <Text fontSize={["sm", null, "md"]}>{excerpt}</Text>
            </Box>
          </Flex>

          <Box ref={ref} height={aspectHeight} width="100%">
            {poster ? (
              <Image
                objectFit="cover"
                fluid={image?.childImageSharp?.fluid}
                style={{ maxHeight: "unset !important", maxWidth: "unset !important" }}
              />
            ) : (
              <Flex alignItems="center" justifyContent="center" bg="banner" height="100%">
                <Logo width={128} height={60} />
              </Flex>
            )}
          </Box>

          <Flex flexDirection={["column", null, null, "row"]} my={["3rem", null, "5rem"]} sx={{ position: "relative" }}>
            <SocialShare title={title} />
            <MDWrapper>
              <Markdown source={content} escapeHtml={false} />
            </MDWrapper>
          </Flex>
        </Container>
      </Box>
    </Layout>
  );
};

export default PostTemplate;
